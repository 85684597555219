<script>
import { mapState, mapActions } from "vuex";
import MainDocument from "@/components/helpers/MainDocument.vue";

export default {
  name: "Licenses",

  components: {
    MainDocument,
  },

  computed: {
    ...mapState({
      documents: (state) => state.licenses.documents,
    }),
  },

  methods: {
    ...mapActions({
      loadDocuments: "licenses/loadDocuments",
    }),
  },

  mounted() {
    this.loadDocuments();
  },
};
</script>

<template>
  <div class="licenses">
    <div v-if="documents" class="licenses__wrapper">
      <h3 class="licenses__title">Материалы</h3>

      <div class="licenses__list">
        <main-document
          v-for="document in documents.materials"
          :key="document.id"
          :title="document.original_name"
          :description="document.description"
          :url="document.url"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.licenses {
  padding: 0 $space-xxl;

  &__wrapper {
    border-radius: 8px;
    background-color: $background-white;
    padding: $space-xl;
    display: inline-block;

    @media (max-width: 768px) {
      width: 100%;
      margin: 0 0 $space-xxl;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $dark-primary;
  }

  @media (min-width: 426px) and (max-width: 576px) {
    padding: 0 $space-xl;
  }

  @media (max-width: 425px) {
    padding: 0 $space-l;
  }

  @media (max-width: 375px) {
    padding: 0 $space-m;
  }
}
</style>
